import React, { useEffect } from 'react'
import { SumaryOrders } from './panels/SumaryOrders'
import { Accounts } from './panels/Accounts'
import { useDispatch, useSelector } from 'react-redux';
import { startloadAccounts } from '../../../app/store/reportes/thunks';
import { MDetail } from './modals/MDetail';
import { Performance } from './panels/Performance';
import { PostOrder } from './panels/PostOrder';
import { PostService } from './panels/PostService';
import { ServicesComplete } from './panels/ServicesComplete';


export const Report = () => {
  //   
  const dispatch = useDispatch();
  const loadFacturas = () => {
    dispatch(startloadAccounts())
  }

  const { usuario, nombre, puesto, avatar, rol } = useSelector(state => state.usuario);

  // console.log(rol);


  return (
    <>
      {/* <SumaryOrders/> */}

      <MDetail />

      <div className="card">

        <div className="card-body">

          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Ordenes Servicio / Ventas</button>
            </li>



            {
              (rol == "Administrador") ?
                <>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={loadFacturas}>Cuentas por Cobrar</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Rendimiento</button>
                  </li>

                </> : <></>
            }

            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-home-tab1" data-bs-toggle="pill" data-bs-target="#pills-home1" type="button" role="tab" aria-controls="pills-home1" aria-selected="true">Seguimiento Post Orden</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-home-tab2" data-bs-toggle="pill" data-bs-target="#pills-home2" type="button" role="tab" aria-controls="pills-home2" aria-selected="true">Seguimiento Post Servicio</button>
            </li>

            {/* <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-home-tab3" data-bs-toggle="pill" data-bs-target="#pills-home3" type="button" role="tab" aria-controls="pills-home3" aria-selected="true">Servicios Realizados</button>
            </li> */}

          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" >
              <SumaryOrders />
            </div>

            <div className="tab-pane fade show " id="pills-home1" role="tabpanel" aria-labelledby="pills-home-tab1" >
              <PostOrder />
            </div>

            <div className="tab-pane fade show " id="pills-home2" role="tabpanel" aria-labelledby="pills-home-tab2" >
              <PostService />
            </div>

            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" >
              <Accounts />
            </div>

            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
              <Performance />
            </div>

            <div className="tab-pane fade show " id="pills-home3" role="tabpanel" aria-labelledby="pills-home-tab3" >
              <ServicesComplete />
            </div>


          </div>

        </div>
      </div>




    </>
  )
}
